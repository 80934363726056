/* eslint-disable */
svg4everybody();
objectFitImages();
/* eslint-enable */

(function ($){
  $(document).ready(function(){
      var eventhandler = function(e) {
          e.preventDefault();
      };
      var height = $(window).height(),
          width = $(window).width();

      // Header menu
      $('.submenu').children('a').on('click', eventhandler);
      $('.submenu').on('click', function() {
          $('.submenu').not(this).children('ul').slideUp(500);
          $('.submenu').not(this).children('a').removeClass('active');
          $(this).children('a').toggleClass('active');
          $(this).children('ul').slideToggle(500);
      });

      // Mobile menu
      $('#mobile-menu').on('click', function() {
          $(this).toggleClass('menu-open');
          $('body').toggleClass('overlay');
          $('.header-nav').toggleClass('active').height(height - 48);
      });
      
      // Mobile search form
      $('#mobile-search').on('click', function() {
        $(this).toggleClass('form-open');
        $('.search').toggleClass('active');
      });


      // Language
      $('.lang-select').on('click', function() {
          $(this).toggleClass('active');
          $('.lang-nav').slideToggle(500);
      });
      
      // Left menu
      $('.service-select').on('click', function() {
          $(this).toggleClass('active');
          $('.service-nav').slideToggle(500);
      });

      // Hide drop-down
      $(document).on('click', function(event) {
        var $clicked = $(event.target);

        if (! $clicked.parents().hasClass("lang")) {
            $('.lang-select').removeClass('active');
            $('.lang-nav').slideUp(500);
        }
        if (! $clicked.parents().hasClass("menu-header")) {
            $('.submenu>a').removeClass('active');
            $('.submenu>ul').slideUp(500);
        }
      });



      if( $('#scroll_wrapper').length ){

      // Vertical menu scrolling
      var vScrollEl = $('.service-nav');
      var vScrollStart = $('#scroll_wrapper').offset().top - $('.wrapper.fixed-top').height()-24;
      var vScrollStop = vScrollStart + $('#scroll_wrapper').height() - vScrollEl.outerHeight();
      var vScrollClass = 'fixed';
      var vScrollStopClass ='fixed-bottom';

      }

      function scrollSideBar(element) {
        var vScrollPosition = $(window).scrollTop();

        if ( vScrollPosition > vScrollStop ) {
         
          element.addClass( vScrollStopClass );
          element.removeClass( vScrollClass );
        
        } else if ( vScrollPosition > vScrollStart ) {
          
          element.addClass( vScrollClass );
          element.removeClass( vScrollStopClass );
        
        } else {

          element.removeClass( vScrollClass );
          element.removeClass( vScrollStopClass );

        }
      }
      if( $('#scroll_wrapper').length ){
      scrollSideBar( vScrollEl );

      $(window).on('scroll', function() {
        scrollSideBar( vScrollEl );
      });
    }

    
      // Window resize
      $(window).on('resize', function() {
        height = $(window).height();
        width = $(window).width();
       
        if(width > 959) {
          $('#mobile-menu').removeClass('menu-open');
          $('body').removeClass('overlay');
          $('.header-nav').removeClass('active').height('auto');
        }
        
        $('.header-nav.active').height(height - 48);


        vScrollStart = $('#scroll_wrapper').offset().top - $('.wrapper.fixed-top').height()-24;
        vScrollStop = vScrollStart + $('#scroll_wrapper').height() - vScrollEl.outerHeight();
        scrollSideBar( vScrollEl );

      });


  });
/* eslint-disable */
})(jQuery);

;( function( $, window, document, undefined )
{
	$( '.inputfile' ).each( function()
	{
		var $input	 = $( this ),
			$label	 = $input.next( 'label' ),
			labelVal = $label.html();

		$input.on( 'change', function( e )
		{
			var fileName = '';

			if( this.files && this.files.length > 1 )
				fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
			else if( e.target.value )
				fileName = e.target.value.split( '\\' ).pop();

			if( fileName )
				$label.find( 'span' ).html( fileName );
			else
				$label.html( labelVal );
		});

		// Firefox bug fix
		$input
		.on( 'focus', function(){ $input.addClass( 'has-focus' ); })
		.on( 'blur', function(){ $input.removeClass( 'has-focus' ); });
	});
})( jQuery, window, document );


$( document ).ready(function() {

  $('img[data-src]').each(function(){
    $(this).attr( 'src',$(this).attr('data-src') );
  });

var btn = $('#totop');
  
    $(window).scroll(function() {
      if ($(window).scrollTop() > 300) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    });
  
    btn.on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop:0}, '300');
    });

    //console.log( "document ready!" );
  /*
    var $sticky = $('.service-nav');
    var $stickyrStopper = $('.subscribe');
    if (!!$sticky.offset()) { // make sure ".sticky" element exists
  
      var generalSidebarHeight = $sticky.innerHeight();
      var stickyTop = $sticky.position().top;
      var stickOffset = 100;
      var stickyStopperPosition = $stickyrStopper.position().top;
      var stopPoint = stickyStopperPosition - generalSidebarHeight - stickOffset;
      var diff = stopPoint + stickOffset;
  
      $(window).scroll(function(){ // scroll event
        var windowTop = $(window).scrollTop(); // returns number
  
        if (stopPoint < windowTop) {
            $sticky.css({ position: 'absolute', top: diff });
        } else if (stickyTop < windowTop+stickOffset) {
            $sticky.css({ position: 'fixed', top: stickOffset });
        } else {
            $sticky.css({position: 'absolute', top: 'initial'});
        }
      });
  
    }
    */
  });
